<template>
  <a-row class="responsive-select-btn">
    <a-col :xs="24" v-if="showTitle">
      <label class="bold mb-2" style="font-size: medium; padding: 4px;">{{
        $t("search_benchmarks.search_asset")
      }}</label>
    </a-col>
    <a-col :xs="24">
      <a-select
        id="busquedadeactivo"
        mode="multiple"
        show-search
        allowClear
        style="width: 100%"
        :filter-option="false"
        :token-separators="[',']"
        :placeholder="$t('generic.forms.tkr_name')"
        v-model:value="value"
        @search="onSearch"
        @blur="onBlur"
        :default-active-first-option="false"
        :max-tag-count="3"
      >
        <a-select-option
          v-for="item in assetByTickerStore.stocksList.data"
          :value="item.id"
          :key="item.id"
          :title="item.name"
          ><span
            v-html="item.name.replace(strRegExp, (match) => `<b>${match}</b>`)"
          ></span
        ></a-select-option>
        <template v-if="assetByTickerStore.stocksList.loading" #notFoundContent>
          <a-spin size="small" />
        </template>
        <template #dropdownRender="{ menuNode: menu }">
          <v-nodes :vnodes="menu" />
          <a-divider style="margin: 4px 0" />
          <a-space style="padding: 12px 12px 4px 24px;">
            <sdButton
              class="element-start"
              :loading="loading"
              type="secondary"
              :disabled="value.length === 0"
              @click="onSelectedItemsSearch"
              style="width: 10rem;"
            >
              {{ $t("generic.buttons.add_to_basket") }}
            </sdButton>
          </a-space>
        </template>
      </a-select>
    </a-col>
    <a-col :xs="24" style="padding-left: 8px;" v-if="autoFilter && shouldShowAutoFilter">
      <p class="the-checkbox">
        <a-checkbox v-model:checked="auto">
          {{ $t("search_funds.auto_filter") }}
        </a-checkbox>
      </p>
    </a-col>
  </a-row>
</template>

<script setup>
import { defineComponent, defineEmits, defineProps, onMounted, ref, computed, watch } from "vue";
import { useAssetByTickerStore } from "@/stores/AssetByTickerStore";
import { useModelWrapper } from "@/composables/useModelWrapper";
import { useAuthStore } from "@/stores/AuthStore";

const emit = defineEmits([
  "update:modelValue",
  "blur",
  "search",
  "selectedSearch",
]);

const VNodes = defineComponent({
  props: {
    vnodes: {
      type: Object,
      required: true,
    },
  },
  render() {
    return this.vnodes;
  },
});

const authStore = useAuthStore();
const assetByTickerStore = useAssetByTickerStore();
const props = defineProps({
  modelValue: { type: Array, default: () => [] },
  tickerType: { type: String, required: true },
  loading: { type: Boolean, required: false },
  showTitle: { type: Boolean, default: () => false },
  autoFilter: { type: Boolean, default: () => true }
});

const value = useModelWrapper(props, emit);
const shouldShowAutoFilter = computed(() => authStore.group_id < 10); // Grupo menor a 10 muestra el checkbox

const searchString = ref("");
const auto = ref(false);

const onSearch = async (e) => {
  if (typeof e == "string") {
    searchString.value = e;
    assetByTickerStore.getAssetListByType(props.tickerType, e, auto.value);
  }
};

const onSelectedItemsSearch = async () => {
  emit("selectedSearch", auto.value);
  setTimeout(() => {
    value.value = [];
  }, 500);
};

onMounted(() => {
  assetByTickerStore.clearList();
  if (!shouldShowAutoFilter.value) {
    auto.value = true;
  }
});

const onBlur = () => {
  emit("blur");
};

const strRegExp = computed(() => {
  return new RegExp(searchString.value, "gi");
});

watch(
  () => value.value,
  () => {
    emit("updatingTkrs", { type: props.tickerType, id: value.value });
  }
);
</script>
<style scoped lang="scss">
.responsive-select-btn {
  align-items: center;
  gap: 4px;
  .resp-btn {
    margin-top: 16px;
    margin-left: 10px;
  }
}

.the-checkbox {
  margin-top: 18px;
}

@media only screen and (max-width: 991px) {
  .responsive-select-btn {
    display: block;
    .resp-btn {
      margin-top: 10px !important;
      margin-left: 0px;
    }
    & > * {
      min-width: 100%;
    }
    .resp-btn-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
    }
  }
}
</style>
