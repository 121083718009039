<template>
  <a-row>
    <a-col :sm="3" :md="6" :xs="24" :lg="3" v-if="isActive('currency')">
      <label class="bold">{{
        $t("search_funds.funds_search_criteria.foreign_exchange")
      }}</label>
      <a-select
        id="busquedadivisas"
        v-model:value="fundsStore.data.currency"
        style="width: 90%; height: 45px"
        class="native-select"
        :token-separators="[',']"
        allowClear
        :getPopupContainer="triggerNode => triggerNode.parentNode"
      >
        <a-select-option
          v-for="(f, i) in divisas"
          :key="i + 'd'"
          :value="f.id"
          :title="f.name"
        >
          {{ f.name }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-col :sm="3" :md="6" :xs="24" :lg="3" v-if="isActive('hedge')">
      <label class="bold">{{
        $t("search_funds.funds_search_criteria.cover")
      }}</label>
      <a-select
        id="cubierta"
        v-model:value="fundsStore.data.cover"
        style="width: 90%; height: 45px"
        class="native-select"
        allowClear
        :getPopupContainer="triggerNode => triggerNode.parentNode"
      >
        <a-select-option
          v-for="(f, i) in cubiertas"
          :key="i + 'd'"
          :value="f === 'true'"
          :title="$t(`search_funds.funds_search_criteria.${f}`)"
        >
          {{ $t(`search_funds.funds_search_criteria.${f}`) }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-col :sm="4" :md="6" :xs="24" :lg="5" v-if="isActive('rat_mstar')">
      <label class="bold">{{
        $t("search_funds.funds_search_criteria.morning_star")
      }}</label>
      <a-select
        id="estrellasmorningstar"
        v-model:value="fundsStore.data.morningStar"
        style="width: 90%; height: 45px"
        class="native-select"
        allowClear
        :getPopupContainer="triggerNode => triggerNode.parentNode"
      >
        <a-select-option
          v-for="(f, i) in estrellasMorningStar"
          :key="i + 'd'"
          :value="f"
          :title="f"
        >
          {{ f }}
        </a-select-option>
      </a-select>
    </a-col>
    <!--  -->
    <!-- NUEVOS INPUTS -->
    <!--  -->

    <a-col :sm="3" :md="6" :xs="24" :lg="3" v-if="isActive('base_currency_id')">
      <label class="bold">{{
        $t("search_funds.funds_search_criteria.base_currency_id")
      }}</label>
      <a-select
        id="base_currency"
        v-model:value="fundsStore.data.base_currency_id"
        style="width: 90%; height: 45px"
        class="native_select"
        allowClear
        :getPopupContainer="triggerNode => triggerNode.parentNode"
      >
        <a-select-option
          v-for="(f, i) in divisas"
          :key="i + 'd'"
          :value="f.id"
          :title="f.name"
        >
          {{ f.name }}
        </a-select-option>
      </a-select>
    </a-col>

    <a-col :sm="3" :md="6" :xs="24" :lg="3" v-if="isActive('fees')">
      <label class="bold">{{
        $t("search_funds.funds_search_criteria.fees")
      }}</label>
      <a-input-number
        id="fees"
        :min="0"
        v-model:value="fundsStore.data.fees"
        style="width: 90%; height: 45px"
        class="native_select"
      />
    </a-col>

    <a-col :sm="3" :md="6" :xs="24" :lg="3" v-if="isActive('min_ini_purchase')">
      <label class="bold">{{
        $t("search_funds.funds_search_criteria.min_ini_purchase")
      }}</label>
      <a-input-number
        id="min_ini_purchase"
        :min="0"
        v-model:value="fundsStore.data.min_ini_purchase"
        style="width: 90%; height: 45px"
        class="native_select"
      />
    </a-col>

    <a-col :sm="3" :md="6" :xs="24" :lg="3" v-if="isActive('fund_size')">
      <label class="bold">{{
        $t("search_funds.funds_search_criteria.fund_size")
      }}</label>
      <a-input-number
        id="fund_size"
        :min="0"
        v-model:value="fundsStore.data.fund_size"
        style="width: 90%; height: 45px"
        class="native_select"
      />
    </a-col>

    <a-col :sm="3" :md="6" :xs="24" :lg="3" v-if="isActive('share_class_size')">
      <label class="bold">{{
        $t("search_funds.funds_search_criteria.share_class_size")
      }}</label>
      <a-input-number
        id="share_class_size"
        :min="0"
        v-model:value="fundsStore.data.share_class_size"
        style="width: 90%; height: 45px"
        class="native_select"
      />
    </a-col>

    <a-col :sm="3" :md="6" :xs="24" :lg="3" v-if="isActive('distribution')">
      <label class="bold">{{
        $t("search_funds.funds_search_criteria.distribution")
      }}</label>
      <a-select
        id="distribution"
        v-model:value="fundsStore.data.distribution"
        style="width: 90%; height: 45px"
        class="native-select"
        allowClear
        :getPopupContainer="triggerNode => triggerNode.parentNode"
      >
        <a-select-option
          v-for="(f, i) in distribution"
          :key="i + 'd'"
          :value="f === 'true'"
          :title="$t(`search_funds.funds_search_criteria.${f}`)"
        >
          {{ $t(`search_funds.funds_search_criteria.${f}`) }}
        </a-select-option>
      </a-select>
    </a-col>

    <a-col
      :sm="4"
      :md="6"
      :xs="24"
      :lg="5"
      v-if="isActive('years_since_launch')"
    >
      <label class="bold">{{
        $t("search_funds.funds_search_criteria.years_since_launch")
      }}</label>
      <a-input-number
        id="years_since_launch"
        v-model:value="fundsStore.data.years_since_launch"
        style="width: 90%; height: 45px"
        class="native_select"
      />
    </a-col>

    <a-col :sm="3" :md="6" :xs="24" :lg="4" v-if="isActive('risk')">
      <label class="bold">{{
        $t("search_funds.funds_search_criteria.risk")
      }}</label>
      <a-select
        id="risk"
        v-model:value="fundsStore.data.risk"
        style="width: 90%; height: 45px"
        class="native_select"
        allowClear
        :options="risks"
        :getPopupContainer="triggerNode => triggerNode.parentNode"
      >
      </a-select>
    </a-col>

    <a-col :sm="3" :md="6" :xs="24" :lg="3" v-if="isActive('ucits')">
      <label class="bold">{{
        $t("search_funds.funds_search_criteria.ucits")
      }}</label>
      <a-select
        id="ucits"
        v-model:value="fundsStore.data.ucits"
        style="width: 90%; height: 45px"
        class="native_select"
        allowClear
        :getPopupContainer="triggerNode => triggerNode.parentNode"
      >
        <a-select-option
          v-for="(f, i) in ucits"
          :key="i + 'd'"
          :value="f === 'true'"
          :title="$t(`search_funds.funds_search_criteria.${f}`)"
        >
          {{ $t(`search_funds.funds_search_criteria.${f}`) }}
        </a-select-option>
      </a-select>
    </a-col>

    <a-col :sm="4" :md="6" :xs="24" :lg="5" v-if="isActive('legal_structure')">
      <label class="bold">{{
        $t("search_funds.funds_search_criteria.legal_structure")
      }}</label>
      <a-select
        id="legal_structure"
        v-model:value="fundsStore.data.legal_structure"
        style="width: 90%; height: 45px"
        class="native_select"
        allowClear
        :options="legal_structures"
        :getPopupContainer="triggerNode => triggerNode.parentNode"
      >
      </a-select>
    </a-col>
  </a-row>
  <!--            Second ROW-->
  <a-row class="mt-3" v-if="isActive('market_cap')">
    <a-space>
      <a-col>
        <span class="pt-3 d-block">{{
          $t("search_funds.funds_search_criteria.patrimony_higher_than")
        }}</span>
      </a-col>
      <a-col>
        <a-input
          class="mt-3"
          type="number"
          style="width: 80px"
          v-model:value="fundsStore.data.patrimony"
        ></a-input>
      </a-col>
      <a-col>
        <span class="pt-3 d-block">{{
          $t("search_funds.funds_search_criteria.million_e")
        }}</span>
      </a-col>
    </a-space>
  </a-row>
  <!--            Third ROW-->
  <a-row class="pt-5">
    <a-col :xs="24">
      <a-checkbox class="mr-3" v-model:checked="fundsStore.data.onlyPasive">
        {{ $t("search_funds.funds_search_criteria.passive_only") }}
      </a-checkbox>
      <a-checkbox class="mr-3" v-model:checked="fundsStore.data.preferedAgency">
        {{ $t("search_funds.funds_search_criteria.preferred_agencies_only") }}
      </a-checkbox>
      <a-checkbox class="mr-3" v-model:checked="fundsStore.data.preferedFunds">
        {{ $t("search_funds.funds_search_criteria.preferred_funds_only") }}
      </a-checkbox>
      <a-checkbox class="mr-3" v-model:checked="fundsStore.data.retailClass">
        {{ $t("search_funds.funds_search_criteria.retail_class_only") }}
      </a-checkbox>
      <a-checkbox
        class="mr-3"
        v-if="shouldShowComparableFunds"
        v-model:checked="fundsStore.data.comparableFunds"
      >
        {{ $t("search_funds.funds_search_criteria.comparable_funds") }}
      </a-checkbox>
    </a-col>
  </a-row>
</template>

<script setup>
import { onMounted, computed, defineProps } from "vue";
import { useFundsStore } from "@/stores/FundsStore";
import { useOperandStore } from "@/stores/OperandStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useI18n } from "vue-i18n";
import { useEtfsStore } from "../../../stores/EtfsStore";

const props = defineProps({
  isEtf: {
    type: Boolean,
    default: false
  }
});

const fundsStore = props.isEtf ? useEtfsStore() : useFundsStore();

const operandStore = useOperandStore();
const authStore = useAuthStore();
const { t } = useI18n();

const shouldShowComparableFunds = computed(() => authStore.group_id < 10);
const divisas = computed(() => operandStore.currencies);
const cubiertas = computed(() => operandStore.yesNo);
const estrellasMorningStar = 5;
const distribution = computed(() => operandStore.yesNo);
const risks = computed(() =>
  operandStore.risks
    .map((risk) => ({
      value: risk.risk,
      label: t(`risks.${risk.risk}`, risk.risk),
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
);
const ucits = computed(() => operandStore.yesNo);
const legal_structures = computed(() =>
  operandStore.legal_structures
    .map((legal_structure) => ({
      value: legal_structure.legal_structure,
      label: t(
        `legal_structures.${legal_structure.legal_structure}`,
        legal_structure.legal_structure
      ),
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
);

const isActive = (key) =>
  !!authStore.config?.funds_filtrable_columns?.general[key];

onMounted(async () => {
  operandStore.getCurrencies();
  operandStore.getLegalStructures();
  operandStore.getRisks();
  if (!shouldShowComparableFunds.value) {
    fundsStore.data.comparableFunds = true;
  }
});
</script>

<style scoped lang="scss">
label {
  margin: 10px;
}

:deep(.native-select) {
  border: 1px #000 solid;
  padding: 0 !important;
  margin: 0 !important;
  .ant-select-selector {
    border: none !important;
    height: 100% !important;
  }

  &.ant-select-open {
    .ant-select-selector {
      border: none !important;
      height: 100% !important;
    }
  }
}

.native_select {
  :deep(.ant-select-selector) {
    height: 45px !important;
  }
}

.bold {
  margin-left: 0px;
}
</style>
