import { defineStore } from "pinia";
import searchFilters from "@/demoData/searchFilters.json";
import client from "@/plugins/axios";
import { useAuthStore } from "@/stores/AuthStore";

const defaultPercentage = {
  percentageFilter: null,
  operand: null,
  percentage: null,
};

export const useFundsStore = defineStore("useFundsStore", {
  state: () => {
    return {
      data: {
        isin: [],
        company: undefined,
        defSeach: null,
        currency: undefined,
        cover: undefined,
        fundsTypes: undefined,
        region_id: undefined,
        contry_id: undefined,
        sector_id: undefined,
        factor: undefined,
        size: undefined,
        morningStar: undefined,
        onlyPasive: false,
        preferedAgency: false,
        preferedFunds: false,
        retailClass: false,
        comparableFunds: false,
        patrimony: undefined,
        category_rent: undefined,
        mix_type: undefined,
        altType: undefined,
        fi_type : undefined,
        term : undefined,
        base_currency_id: undefined,
        fees: undefined,
        min_ini_purchase: undefined,
        fund_size: undefined,
        share_class_size: undefined,
        distribution: undefined,
        years_since_launch: undefined,
        risk: undefined,
        ucits: undefined,
        legal_structure: undefined,
      },
      saved_searches: [],
      selected_search: null,
      searchId: null,
      selector: [],
      funds: [],
      percentages: [{ ...defaultPercentage }],
      categories: [],
      searchFilters: searchFilters,
      categoryfilters: [],
      percentagefilters: {},
      fundsTypes: [],
      factors: ["High dividend", "Value", "Blend", "Growth"],
      dynamic: [],
      dynamicGraph: [],
      conversion_ratios: [],
      isLoading: false,
      isLoadingDelete: false,
      isLoadingSearches: false,
      error: null,
      selectCriteriaList: [
        { key: 0, value: "add_all" },
        { key: 1, value: "change_criteria" },
      ],
      needToSave: true,
    };
  },
  getters: {
    getDynamicList: (state) => {
      return state.dynamic;
    },
    getDynamicGraph: (state) => {
      return state.dynamicGraph;
    },
    mapConfigToModel: () => {
      return (config) => {
        switch (config) {
          case "country":
            return "country_id";
          case "sector":
            return "sector_id";
          case "fi_types":
            return "fi_type";
          case "fi_type":
            return "fi_type";
          case "term":
              return "term";
          case "region":
            return "region_id";
          case "alternative_type":
            return "altType";
          default:
            return config;
        }
      };
    },
  },
  actions: {
    addPercentage() {
      this.percentages.push({ ...defaultPercentage });
    },
    removePercentage(index) {
      this.percentages.splice(index, 1);
    },
    addDynamic(item) {
      this.dynamic.push(item);
    },
    removeDynamic(item) {
      this.dynamic.remove(item);
    },
    async generateDynamicGraph(isins) {
      try {
        const authStore = useAuthStore();
        const data = { isin_tkr_ids: isins, from: authStore?.chartRange || null };
        return client.post("/funds/graph", data).then((response) => {
          authStore.logAction('fund_graphing');
          this.dynamicGraph = response.data.map((serie) => ({
            ...serie,
            series: serie.series.map((e) => {
              return [e[0] * 1000, e[1]];
            }),
          }));
        });
      } catch (err) {
        this.error = err;
      }
    },
    async getConversionRatios(params = null, currencies = []) {
      try {
        return client
          .get("/funds/conversions_ratios", {
            params: {
              ...params,
              currencies
            }
          })
          .then((response) => {
            this.conversion_ratios = response.data;
          });
      } catch (err) {
        this.error = err;
      }
    },
    getFundDataByKey(key) {
      return this.funds.filter((f) => f.key === key).first();
    },
    getFundData(key) {
      return this.funds.filter((f) => f.id === key).first();
    },
    async getFundsTypes() {
      try {
        return client.get("/fund-types").then((response) => {
          this.fundsTypes = response.data.data;
          return response.data.data;
        });
      } catch (err) {
        return (this.error = err);
      }
    },
    async getSelector() {
      try {
        return client.get("/funds/selector").then((response) => {
          this.selector = response.data.data;
          return response.data;
        });
      } catch (err) {
        this.error = err;
      }
    },
    async fetchPortfolioFunds() {
      try {
        return client.get("/funds/portfolio-funds").then((response) => {
          return response.data;
        });
      } catch (err) {
        this.error = err;
      }
    },
    async searchFunds() {
      this.isLoading = true;
      const authStore = useAuthStore();
      try {
        const data = {
          isin: this.data.isin,
          company: this.data.company,
          config: authStore.config,
        };
        authStore.logAction('search_funds');
        return client.post("/funds", data).then((response) => {
          this.funds = response.data.data;
          return response.data.data;
        });
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },

    async fetchMySearches() {
      this.isLoadingSearches = true;
      try {
        await client.get("/user-searches").then(response => {
          this.isLoadingSearches = false;
          this.saved_searches = response.data;
        });
      } catch (err) {
        this.isLoadingSearches = false;
        this.error = err;
      }
    },

    async saveSearch(new_search_name) {
      try {
        const data = {
          ...this.data,
          categories: this.categories.length ? this.categories : null,
          percentages: this.percentages.length ? this.percentages : null,
        };
        client.post(`/user-searches/save`, { new_search_name, data: JSON.stringify(data), id: this.searchId  }).then(response => {
          this.saved_searches = response.data;
          this.selected_search = this.saved_searches.find(item => item.id === this.searchId);
          this.searchId = null;
          return response.data;
        });
      } catch (err) {
        this.error = err;
        return err;
      }
    },

    async removeSearch(searchId) {
      this.isLoadingDelete = true;
      try {
        return client.delete(`/user-searches/${searchId}`).then((response) => {
          this.isLoadingDelete = false
          this.saved_searches = this.saved_searches.filter(item => item?.id !== searchId);
          return response.data.data;
        });
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoadingDelete = false;
      }
    },

    async searchFundsByIsinTkr(isinTkr) {
      this.isLoading = true;
      const authStore = useAuthStore();
      try {
        const result = [];
        authStore.logAction('search_funds');
        for (let i = 0; i < isinTkr?.length / 100; i ++) {
          const data = {
            isin: isinTkr.slice(i * 100, (i + 1) * 100),
            config: { fixed_ratio_columns: authStore.config.fixed_ratio_columns },
          };
          await client.post("/funds", data).then((response) => {
            result.push(...response.data.data);
          });
        }
        this.funds = result;
        return result;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },

    async searchFundsFiltering(searchData) {
      const data = {
        ...this.data,
        categories: this.categories.length ? this.categories : null,
        percentages: this.percentages.length ? this.percentages : null,
      };
      if (searchData) {
        this.needToSave = false;
      } else {
        if (this.selected_search && this.selected_search.search_sql) {
          this.needToSave = this.selected_search.search_sql !== JSON.stringify(data);
        } else {
          this.needToSave = true;
        }
      }
      this.isLoading = true;
      const authStore = useAuthStore();
      try {
        authStore.logAction('search_funds');
        return client.post("/funds/filters", searchData ?? data).then((response) => {
          this.funds = response.data.funds;
          return response.data.funds;
        });
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    getCategoryFilterFor(assetTitle) {
      return this.categoryfilters.first((f) => f.title === assetTitle);
    },
    resetData(e = {}) {
      this.data = {
        isin: [],
        company: e?.company,
        defSeach: e?.defSeach || null,
        currency: e?.currency,
        cover: e?.cover,
        fundsTypes: e?.fundsTypes,
        region_id: e?.region_id,
        country_id: e?.country_id,
        sector_id: e?.sector_id,
        factor: e?.factor,
        size: e?.size,
        morningStar: e?.morningStar,
        onlyPasive: e?.onlyPasive || false,
        preferedAgency: e?.preferedAgency || false,
        preferedFunds: e?.preferedFunds || false,
        retailClass: e?.retailClass || false,
        comparableFunds: e?.comparableFunds || false,
        patrimony: e?.patrimony,
        category_rent: e?.category_rent,
        mix_type: e?.mix_type,
        altType: e?.altType,
        fi_type: e?.fi_type,
        term: e?.term,
      };
      this.fundsTypes = e?.fundsTypes;
      this.percentages = e?.percentages || [{ ...defaultPercentage }];
    },
  },
  // persist: {
  //     enabled: true,
  //     strategies: [
  //         {
  //             key: 'selector',
  //             storage: localStorage,
  //         },
  //         {
  //             key: 'funds',
  //             storage: localStorage,
  //         },
  //         {
  //             key: 'fundsTypes',
  //             storage: localStorage,
  //         }
  //     ],
  // }
});
