<template>
  <a-input
    :placeholder="$t('generic.forms.company')"
    v-model:value="query"
    @focusin="showList()"
    @focusout="hideList()"
    @keyup="searchFundsCompany"
    class="this-input"
  />
  <template v-if="visible">
    <div class="result-list">
      <template v-if="assets.length === 0 && query !== '' && query.length > 2">
        <span v-if="searching">Buscando...</span>
        <span v-else>Sin resultados</span>
      </template>
      <ul class="result-container" v-else>
        <li v-for="(f, i) in assets" :key="i + '_' + dkey">
          <div class="the-result" @click="openModal(f)">{{ f.company }}</div>
        </li>
      </ul>
    </div>
  </template>
  <a-col 
    v-if="shouldShowAutoFilter"  
    :xs="24" style="padding-left: 8px; margin-top: 16px;">
    <p>
      <a-checkbox v-model:checked="assetByTickerStore.only_my_bank">
        {{ $t("search_funds.auto_filter") }}
      </a-checkbox>
    </p>
  </a-col>
  
  <a-modal
    v-model:visible="modalVisible"
    :title="selectedCompany"
    :type="'primary'"
    width="80vw"
    @ok="handleOk"
    @cancel="handleCancel"
    class="selected-company-modal"
  >
    <template #footer>
      <sdButton
        key="submit"
        type="secondary"
        :loading="loading"
        style="width: 12rem;"
        @click="handleOk"
        :disabled="!selectedRowIndex.length"
        >{{ $t("funds_printer.add_to_basket") }}</sdButton
      >
    </template>

    <a-table
      v-if="modalVisible"
      :row-selection="{ selectedRowIndex, onChange: onSelectChange }"
      :columns="columns"
      :data-source="tableRows"
      :key="columns.key"
      :loading="modalLoading"
    />
  </a-modal>
</template>

<!-- :rowSelection="rowSelection" -->

<script setup>
import { defineEmits,onMounted, computed, defineProps, ref } from "vue";
import { useAssetByTickerStore } from "@/stores/AssetByTickerStore";
import { useModelWrapper } from "@/composables/useModelWrapper";
import { useAuthStore } from "@/stores/AuthStore";
import { useI18n } from "vue-i18n";

const { d, n } = useI18n();

const authStore = useAuthStore();

const months = authStore.config["number_months_to_show_positions"];
// import { useFundsStore } from '@/stores/FundsStore';

// const fundsStore = useFundsStore()
const assetByTickerStore = useAssetByTickerStore();
const props = defineProps({
  modelValue: { type: String },
  title: { type: String, default: "Fondos que tienen una compañía" },
  tickerType: { type: String, required: true },
});
const assets = ref([]);
const dkey = ref(0);
const visible = ref(false);
const query = ref("");
const emit = defineEmits(["update:modelValue", "selectedFunds"]);
const value = useModelWrapper(props, emit);
console.log(value);
const timer = ref(null);
const searching = ref(false);
const modalVisible = ref(false);
const selectedCompany = ref("");
const selectedCompanyId = ref(null);
const tableRows = ref([]);
const modalLoading = ref(false);
const selectedRowIndex = ref([]);

const onSelectChange = (selected) => {
  selectedRowIndex.value = selected;
};

const shouldShowAutoFilter = computed(() => authStore.group_id < 10); // Grupo menor a 10 muestra el checkbox


const handleOk = () => {
  let theSelectedRows = selectedRowIndex.value;
  emit("selectedFunds", { theSelectedRows, selectedCompanyId });

  handleCancel();
};

const columns = ref([]);

const showList = () => {
  visible.value = true;
};

const hideList = () => {
  setTimeout(() => {
    visible.value = false;
  }, 300);
};

const searchFundsCompany = async () => {
  if (query.value.length < 2) {
    return;
  }
  searching.value = true;
  clearTimeout(timer.value);

  timer.value = setTimeout(() => {
    assetByTickerStore.getFamilyPositions(query.value).then((r) => {
      assets.value = r;
      searching.value = false;
      dkey.value++;
    });
  }, 300);
};

const openModal = async (selected) => {
  selectedCompany.value = selected.company;
  selectedCompanyId.value = selected.position_name_id;
  selectedRowIndex.value = [];
  tableRows.value = [];
  modalVisible.value = true;
  modalLoading.value = true;
  const response = await assetByTickerStore.getFamilyPositionsFunds(
    selected.family,
    months,
    selected.position_name_id
  );

  columns.value = response.headers.map((col) => {
    return {
      title: col.type === "date" ? d(new Date(col.value), "short") : col.value,
      dataIndex: col.type === "date" ? col.value : col.field,
      key: col.type === "date" ? col.value : col.field,
    };
  });
  const dataRows = response.dataRows.map((val) => {
    let row = {
      key: val.id,
      id: val.id,
    };
    response.headers.forEach((element) => {
      if (element.type === "date") {
        const dataValue = val.values.find((e) => e.date === element.value)
          ? val.values.find((e) => e.date === element.value).value
          : null;
        row[element.value.toString()] =
          dataValue !== null
            ? n(dataValue, "percent", {
                minimumFractionDigits: 2,
              })
            : "";
      } else {
        row[element.field] = val[element.field];
      }
    });

    return row;
  });
  const lastValue =
    response.headers[response.headers.length - 1]?.value?.toString();
  dataRows.sort(function (a, b) {
    return b[lastValue].match(/\d+/) - a[lastValue].match(/\d+/);
  });
  tableRows.value = dataRows;
  modalLoading.value = false;
};
const handleCancel = () => (modalVisible.value = false);

onMounted(() => {
  if (!shouldShowAutoFilter.value) {
    assetByTickerStore.only_my_bank = true;
  }
});

</script>

<style scoped lang="scss">
label {
  margin-bottom: 10px;
}

.this-input {
  background-color: transparent !important;
  height: 46px;
  :deep(.placeholder) {
    margin-left: 300px;
  }
  &::placeholder {
    padding-left: 5px;
    opacity: 0.7;
  }
}

.result-list {
  width: 100%;
  .the-result {
    margin: 4px 0px;
    &:hover {
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.result-container {
  padding-left: 10px;
}
</style>
<style lang="scss">
.selected-company-modal {
  .ant-table {
    border-radius: 0 !important;
    border-bottom: 1px solid #000;
  }
  .ant-table-thead {
    background-color: #004851;
    th {
      background-color: inherit;
      color: white;
      border-radius: 0 !important;
    }
    .ant-table-selection-column {
      & .ant-table-header-column {
        display: none;
      }
    }
  }
  .ant-modal-content {
    padding: 1rem 2.5rem;
  }
  .ant-modal-title {
    border-bottom: 1px solid #000;
  }
  .ant-modal-footer {
    border: none;
    text-align: left;
  }
  .ant-modal-header {
    border: none;
  }
}
</style>
