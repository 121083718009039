import { defineStore } from "pinia";
import staticData from "@/config/config";

export const useThemeStore = defineStore("useThemeStore", {
  state: () => {
    return {
      data: staticData.darkMode,
      rtlData: staticData.rtl,
      topMenu: staticData.topMenu,
      loading: false,
      rtlLoading: false,
      menuLoading: false,
      error: null,
      collapsedMenu: false,
    };
  },
  getters: {
    fullName: (state) =>
      state.user && state.user.firstname && state.user.lastname
        ? `${state.user.firstname} ${state.user.lastname}`
        : "",
    role_id: (state) => state.user?.role_id,
    professional: (state) => state.user?.professional,
    group_id: (state) => state.user?.user_group_id
  },
  actions: {
    async changeLayoutMode(value) {
      try {
        this.loading = true;
        const state = this;
        setTimeout(() => {
          state.loading = false;
          state.data = value;
        }, 10);
      } catch (err) {
        this.loading = false;
        this.error = err;
      }
    },

    async changeRtlMode(value) {
      try {
        this.loading = true;
        const state = this;
        setTimeout(() => {
          state.loading = false;
          state.rtlData = value;
        }, 10);
      } catch (err) {
        this.loading = false;
        this.error = err;
      }
    },

    async changeMenuMode(value) {
      try {
        this.loading = true;
        const state = this;
        setTimeout(() => {
          state.loading = false;
          state.topMenu = value;
        }, 10);
      } catch (err) {
        this.loading = false;
        this.error = err;
      }
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "theme",
        storage: localStorage,
      },
    ],
  },
});
