<template>
  <div class="chat-interface">
    <div class="chat-wrapper" :class="{ 'active': showChat }" >
      <label class="chat-button" @click="showChat = !showChat">
        <img
          :src="require(`../../../static/img/chat-icon.svg`)"
          alt="chat-icon"
          width="16"
        />
      </label>
      <div
        class="chat-content"
        ref="chatContent"
        :style="{ width: chatWidth + 'px', height: chatHeight + 'px' }"
      >
        <iframe
          :src="iframeUrl"
          id = "chat-iframe"
          title="Chat brokvisor"
          allow="clipboard-write"
          style="width: 100%; height: 100%; border: none"
        ></iframe>
        <div class="chat-resize-handle" @click="toggleChatSize"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import { useAuthStore } from "../../../stores/AuthStore";

const authStore = useAuthStore();
const iframeUrl = ref('');
const showChat = ref(false);
const token = ref('');


const chatContent = ref(null);
//const resizeHandle = ref(null);
const chatWidth = ref(560); // Ancho inicial
const chatHeight = ref(580); // Alto inicial
const smallSize = { width: 560, height: 580 }; // Tamaño pequeño
const largeSize = { width: 800, height: 900 }; // Tamaño grande

const handleClickEvent = (evt) => {
  if (!evt.target.closest('.chat-interface') && showChat.value) {
    showChat.value = false;
  }
};

const toggleChatSize = () => {
  if (chatWidth.value === smallSize.width && chatHeight.value === smallSize.height) {
    chatWidth.value = largeSize.width;
    chatHeight.value = largeSize.height;
  } else {
    chatWidth.value = smallSize.width;
    chatHeight.value = smallSize.height;
  }
};

//let isResizing = ref(false); // Variable para controlar si está en modo de redimensionamiento

// Incremento máximo permitido por movimiento (en píxeles)
// const resizeStep = 10;
// const onResize = (moveEvent) => {
//     if (!isResizing.value) return; // Solo redimensiona si está activo el modo de redimensionamiento

//     const rect = chatContent.value.getBoundingClientRect();
//     const minWidth = 200; // Ancho mínimo permitido
//     const minHeight = 200; // Alto mínimo permitido

//     // Calcular nuevos valores basados en el movimiento del ratón
//     const deltaX = moveEvent.clientX - rect.right;
//     const deltaY = moveEvent.clientY - rect.bottom;

//     // Limitar los cambios al tamaño máximo por movimiento (resizeStep)
//     const stepX = Math.sign(deltaX) * Math.min(resizeStep, Math.abs(deltaX));
//     const stepY = Math.sign(deltaY) * Math.min(resizeStep, Math.abs(deltaY));

//     const newWidth = Math.max(minWidth, chatWidth.value - stepX);
//     const newHeight = Math.max(minHeight, chatHeight.value + stepY);

//     chatWidth.value = newWidth;
//     chatHeight.value = newHeight;

//     // Mantener fija la esquina superior derecha
//     chatContent.value.style.right = `0px`;
//   };

  // const onMouseUp = () => {
  //   if (isResizing.value) {
  //     isResizing.value = false; // Desactiva el redimensionamiento cuando se suelta el ratón
  //     document.removeEventListener("mousemove", onResize);
  //     document.removeEventListener("mouseup", onMouseUp);
  //   }
  // };



onMounted(async () => {

await authStore.getTokens();

const higherCreditToken = authStore.user_tokens.reduce((prev, current) => {
  return (prev.credits > current.credits) ? prev : current;
});
// console.log(higherCreditToken);
// console.log(higherCreditToken.token);
token.value = higherCreditToken.token;

iframeUrl.value = `https://chat.brokvisor.com/?token=${token.value}`;
document.addEventListener('click', handleClickEvent);
// resizeHandle.value.addEventListener("mousedown", (event) => {
//     event.preventDefault();
//     event.stopPropagation();
//     isResizing.value = true; // Activa el redimensionamiento
//     document.addEventListener("mousemove", onResize);
//     document.addEventListener("mouseup", onMouseUp);
//   });

});

onUnmounted(() => {
  document.removeEventListener('click', handleClickEvent);
  //document.removeEventListener("mousemove", onResize);
  //document.removeEventListener("mouseup", onMouseUp);
});
</script>
<style scoped lang="scss">
.layout {
  position: relative;
  .chat-interface {
    z-index: 999;
    .chat-wrapper {
      position: relative;
      .chat-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: white;
        cursor: pointer;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
          0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        transition: all 0.1s linear;
      }

      &.active .chat-button {
        transform: scale(1.1);
      }

      .chat-content {
        position: absolute;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
          0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        right: 0; /* Fija la esquina superior derecha */
        top: 16px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s linear;
        display: flex;
        flex-direction: column;

        .chat-resize-handle {
          position: absolute;
          width: 16px;
          height: 16px;
          bottom: 0;
          left: 0;
          background: linear-gradient(45deg, #ccc, transparent);
          cursor: sw-resize;
          border-radius: 50%;
        }
      }

      &.active .chat-content {
        opacity: 1;
        visibility: visible;
        transform: translateY(5%);
      }
    }
  }
}
</style>
